import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-add-site',
  templateUrl: './add-site.component.html',
  styleUrls: ['./add-site.component.scss']
})
export class AddSiteComponent implements OnInit {
  @Output() close = new EventEmitter();
  selectedSite: any = {};
  @Input() clientList: any = [];
  @Input() configTemplates: any = [];
  @Input() users: any = [];
  constructor(public webReq: WebRequestsService,public notify: NotificationsService) { }

  ngOnInit(): void {
  }

  generateRandomKey() {
    this.selectedSite.siteKey = this.getRandomNumber(16);
  }

  getRandomNumber(digit: any) {
    return Math.random().toFixed(digit).split('.')[1];
  }
  // Output: 7170981998871888

  addSite() {

    this.webReq.commissionSite(this.selectedSite.siteName, '', this.selectedSite.customer, this.selectedSite.secondaryController, this.selectedSite.configTemplate, this.selectedSite.contactPerson).subscribe((result: any) => {
      this.notify.openMessage(result.message, result.success == true ? 'success' : 'error', 3000);
      this.close.emit(result.document._id);
    })
  }

  closing() {
    this.close.emit(null);
  }

}
