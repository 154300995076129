import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {

  users = [];
  dataSource = new MatTableDataSource();
  dataSourceGroups = new MatTableDataSource();
  displayedColumnsGroups = ['groupname', 'count']
  displayedColumns = ['locked','username', 'name', 'surname', 'email', 'cellphone', 'admin','vkeys','configurator', 'canHandover', 'canCommission','subcontractor' ] 
  addingUser = false;
  selectedUser = null;
  siteList = [];
  filterValue = "";
  subcontractors = [];
  allUserGroups: any = [];
  filteredUserGroups: any = [];
  addingUserGroup = false;
  selectedUserGroup:any;
  editingUserGroup = false;
  showGroups = false;
  lockingUser = false;
  canApprove = false;
  resettingDevice = false;

  addingUserSites = false;
  addedUser = false;
  addedEmail:any = null;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('myPaginatorGroups') paginatorGroups!: MatPaginator;

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('groupsSort') sortGroups!: MatSort;
  


  constructor(public webReq: WebRequestsService, public loader: LoaderService, public notify: NotificationsService) { }

  ngOnInit(): void {
    let canApproveObj = localStorage.getItem('canApproveUsers');
    if(canApproveObj == 'true') {
      this.canApprove = true
    }
    this.getUsers();
    this.getSites();
    this.getSubcontractors();
    this.getUserGroups();
  }
  toggleGroups(event) {
    if(event.index == 0) {
      this.showGroups = false;
    } else {
      this.showGroups = true;
    }
  }

  lockUser() {
    this.webReq.blockUser(this.selectedUser).subscribe(result => {
      if(result.success == true) {
        this.lockingUser = false;
        this.selectedUser.blocked = true;
        this.notify.openMessage('User Locked', 'success', 5);
      } else {
        this.notify.openMessage(result.message, 'error', 5);
      }
    }) 
  }

  getUsers() {
    this.webReq.getUsers().subscribe(users => {
      this.users = users;
      this.dataSource.data = this.users;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator; 
    });
  }

  getSubcontractors() {
    this.webReq.getSubcontractors().subscribe(sc => {
      this.subcontractors = sc;
    })
  }

  getSites() {
    this.webReq.getSitesAll().subscribe(sites => {
      this.siteList = sites;
    })
  }

  resetPassword() {
    this.webReq.resetPassword(this.selectedUser).subscribe(res => {
      // this.loader.isLoading = false;
      alert('User Password has been reset');
    }, err => {
      this.loader.isLoading = false;
      alert('Failed to reset user`s password');
    })
  }

  deleteUser() {
    this.loader.isLoading = true;
    this.webReq.globalDeleteUser(this.selectedUser).subscribe(res => {
      if (res.success != false) {
        this.loader.isLoading = false;
        this.notify.openMessage(res.message, 'success');
        this.selectedUser = null;
        this.getUsers();

      } else {
        this.loader.isLoading = false;
        this.notify.openMessage("User not deleted", 'error');
      }
    }, err => {
      this.loader.isLoading = false;
      this.notify.openMessage("Server error", "error");
    });
  }
  updateUser() { // Should be able to update username, cellphone, sites
    this.webReq.globalUpdateUser(this.selectedUser).subscribe(res => {
      alert(res.message);
      this.selectedUser = null;
      this.getUsers();
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.data = this.users.filter((x: any) => x.username.toLowerCase().includes(filterValue.toLowerCase()) || 
    x.email.toLowerCase().includes(filterValue.toLowerCase()) ||
    x.cellphone.toLowerCase().includes(filterValue.toLowerCase()) ||
    x.subcontractor.toLowerCase().includes(filterValue.toLowerCase()) ||
    x.name.toLowerCase().includes(filterValue.toLowerCase()) ||
    x.surname.toLowerCase().includes(filterValue.toLowerCase()) );
  }

  getUserGroups() {
    this.webReq.getUserGroups().subscribe((userGroups:any) => {
      this.allUserGroups = userGroups.userGroups;
      this.filteredUserGroups = this.allUserGroups;
      this.dataSourceGroups.data = this.filteredUserGroups;
      this.dataSourceGroups.paginator = this.paginatorGroups;
      // this.dataSourceGroups.sort = this.sort;
    })
  }
  
  applyUserGroupFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredUserGroups = this.allUserGroups.filter(x => x.groupName.toLowerCase().includes(filterValue) || x.users.length.toString() == filterValue);
    if(filterValue == "") {
      this.filteredUserGroups = this.allUserGroups;
    }
    this.dataSourceGroups.data = this.allUserGroups.filter(x => x.groupName.toLowerCase().includes(filterValue) || x.users.length.toString() == filterValue);
    if(filterValue == "") {
      this.dataSourceGroups.data = this.allUserGroups;
    }
  }

  setUserType(type:string) {
    let canApproveObj = localStorage.getItem('canApproveUsers');
    if(canApproveObj == 'true') {
      this.canApprove = true
    }
    if(!this.canApprove)
    {
    type='none';
    }
    
    if(type == 'basic') {
      this.selectedUser.userType = 'basic';
      this.selectedUser.isAdmin = false;
      this.selectedUser.generateTokens = false;
      this.selectedUser.canConfigure = false;
      this.selectedUser.canCommission = false;
      this.selectedUser.canHandover = false;
    } else if(type == 'se') {
      this.selectedUser.userType = 'se';
      this.selectedUser.isAdmin = false;
      this.selectedUser.generateTokens = false;
      this.selectedUser.canConfigure = true;
      this.selectedUser.canCommission = true;
      this.selectedUser.canHandover = true;
    } else if (type == 'installer') {
      this.selectedUser.userType = 'installer';
      this.selectedUser.isAdmin = false;
      this.selectedUser.generateTokens = false;
      this.selectedUser.canConfigure = true;
      this.selectedUser.canCommission = true;
      this.selectedUser.canHandover = true;
    } else if (type == 'advanced') {
      this.selectedUser.userType = 'advanced';
      this.selectedUser.isAdmin = true;
      this.selectedUser.generateTokens = true;
      this.selectedUser.canConfigure = true;
      this.selectedUser.canCommission = true;
      this.selectedUser.canHandover = true;
    }else if(type=='none'){
      this.selectedUser.userType = 'none';
      this.selectedUser.isAdmin = false;
      this.selectedUser.generateTokens = false;
      this.selectedUser.canConfigure = false;
      this.selectedUser.canCommission = false;
      this.selectedUser.canHandover = false;
    }
  }

  validateData(){
    const emailRegex  = new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm");

    
    let emailValid = emailRegex.test(this.selectedUser.email);

    if(!emailValid || this.selectedUser.subcontractor == null || this.selectedUser.subcontractor == undefined || this.selectedUser.subcontractor == '' || this.selectedUser.cellphone == '' || (this.selectedUser.idNumber == undefined || this.selectedUser.idNumber.length < 5)) {
      return true;
    } else{
      return false
    } 
    
  }

  resetDevices() {
    this.webReq.resetDevices(this.selectedUser).subscribe(result => {
      if(result.success == true) {
        this.notify.openMessage('User devices reset', 'success', 3);
        this.resettingDevice = false;
      } else {
        this.notify.openMessage('Failed to reset devices', 'error', 3);
      }
    })
  }

  completeAddUser(evt: any) {
    if(evt != null) {
      this.addedUser = true;
      this.addedEmail = evt;
    }
  }

  addNewUserToSites() {
    this.selectedUser = this.users.filter(x => x.email == this.addedEmail)[0];
    this.addingUserSites = true;
  }
}
