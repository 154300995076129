<mat-card class="mat-elevation-z8">
    <div fxLayout="column">
        <h2>Site</h2>
        <div>
            <label>Details for Site:</label>
            <div fxLayout="column">
                <!-- Site Name -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Site Name</mat-label>
                        <input matInput [(ngModel)]="selectedSite.siteName">
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Customer</mat-label>
                        <input matInput [(ngModel)]="selectedSite.customer">
                    </mat-form-field>
                </div>

                <!-- Config Template -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Config Template</mat-label>
                        <mat-select [(ngModel)]="selectedSite.configTemplate">
                            <mat-option>None</mat-option>
                            <mat-option *ngFor="let template of configTemplates"
                                [value]="template._id">{{template.name}} <mat-icon style="position: absolute; right: 0;" [ngStyle]="{'color': template.color}">trip_origin</mat-icon> </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-slide-toggle [(ngModel)]="selectedSite.secondaryController">Secondary
                        Controller</mat-slide-toggle>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" *ngIf="selectedSite.secondaryController">
                    <mat-form-field appearance="outline">
                        <mat-label>Secondary Controller Name</mat-label>
                        <input matInput [(ngModel)]="selectedSite.secondaryName">
                    </mat-form-field>
                </div>

                <mat-form-field appearance="outline">
                    <mat-label>Contact Person</mat-label>
                    <mat-select [(ngModel)]="selectedSite.contactPerson">
                      <mat-option>None</mat-option>
                      <mat-option *ngFor="let user of users" [value]="{name: user.name + ' '+ user.surname, email: user.email, cellphone: user.cellphone}">{{user.username}} {{user.email}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <label>Name: {{selectedSite.contactPerson?.name}} {{selectedSite.contactPerson?.surname}}</label>
                  <label>Email: {{selectedSite.contactPerson?.email}}</label>
                  <label>Cell: {{selectedSite.contactPerson?.cellphone}}</label>

                <button style="margin: 4px;" mat-raised-button color="primary" (click)="addSite()">
                    <mat-icon>save</mat-icon> Create Site
                </button>
                <button style="margin: 4px;" mat-raised-button color="warn" (click)="closing()">
                    <mat-icon>cancel</mat-icon> Cancel
                </button>
            </div>
        </div>
    </div>
</mat-card>